// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webcam-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: initial;
    background-color: rgba(76, 74, 74, 0.655);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);

}

.webcam{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.video-container{
    position: relative;
    overflow: hidden;
    width: 70vw;
    height: 70vw;
    max-width: 300px;
    max-height: 300px;
    bottom: 1rem;
}

.video-container video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.webcam img{
    width: 70vw;
    height: 70vw;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 50%;
}

.webcam__controls{
    display: flex;
    justify-content: space-between;
    font-size: 3.5rem;
}

.webcam-err{
    text-align: center;
    position: absolute;
    font-size: 4rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/webcam/webcam.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,yCAAyC;IACzC,kCAA0B;YAA1B,0BAA0B;;AAE9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,2CAA2C;IAC3C,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".webcam-container{\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    pointer-events: initial;\n    background-color: rgba(76, 74, 74, 0.655);\n    backdrop-filter: blur(2px);\n\n}\n\n.webcam{\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n}\n\n.video-container{\n    position: relative;\n    overflow: hidden;\n    width: 70vw;\n    height: 70vw;\n    max-width: 300px;\n    max-height: 300px;\n    bottom: 1rem;\n}\n\n.video-container video{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.webcam img{\n    width: 70vw;\n    height: 70vw;\n    max-width: 300px;\n    max-height: 300px;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.webcam__controls{\n    display: flex;\n    justify-content: space-between;\n    font-size: 3.5rem;\n}\n\n.webcam-err{\n    text-align: center;\n    position: absolute;\n    font-size: 4rem;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
