// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    all: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.modal.open{
    opacity: 1;
    pointer-events: initial;
}

.modal.closed{
    opacity: 0;
    pointer-events: none;
}


/* ERROR SPECIFIC MODAL */
.error__modal {
    width: 400px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    font-family: 'Open Sans', Helvetica, sans-serif;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.error__modal.dismissable {
    justify-content: space-between;
}

.error__modal.not-dismissable {
    padding: 10px 20px;
    gap: 30%;
}


.error-image {
    margin-top: 10px;
}

.error-title {
    font-size: 26px;
    line-height: 24px;
    font-weight: 400;
    margin: 24px 0;
}

.error-message {
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
}

.error-dismiss-btn {
    height: 40px;
    width: 100%;
    background-color: #F65656;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    pointer-events: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;;AAGA,yBAAyB;AACzB;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,+CAA+C;IAC/C,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;AACZ;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".modal {\n    all: unset;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height: 100%;\n}\n\n.modal.open{\n    opacity: 1;\n    pointer-events: initial;\n}\n\n.modal.closed{\n    opacity: 0;\n    pointer-events: none;\n}\n\n\n/* ERROR SPECIFIC MODAL */\n.error__modal {\n    width: 400px;\n    height: 300px;\n    background-color: #fff;\n    border-radius: 3px;\n    text-align: center;\n    font-family: 'Open Sans', Helvetica, sans-serif;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n\n.error__modal.dismissable {\n    justify-content: space-between;\n}\n\n.error__modal.not-dismissable {\n    padding: 10px 20px;\n    gap: 30%;\n}\n\n\n.error-image {\n    margin-top: 10px;\n}\n\n.error-title {\n    font-size: 26px;\n    line-height: 24px;\n    font-weight: 400;\n    margin: 24px 0;\n}\n\n.error-message {\n    font-size: 14px;\n    font-weight: 300;\n    line-height: 19px;\n}\n\n.error-dismiss-btn {\n    height: 40px;\n    width: 100%;\n    background-color: #F65656;\n    color: #fff;\n    line-height: 40px;\n    font-size: 14px;\n    font-weight: 400;\n    cursor: pointer;\n    pointer-events: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
